/* eslint-disable max-len, jsx-a11y/anchor-has-content */
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Link from '../i18n/Link';
import Layout, { Seo } from '../components/Layout';
import Cover from '../components/Elements/Cover';
import PageSection from '../components/Elements/PageSection';
import withTranslations from '../i18n/withTranslations';

const Page = () => {
  const { t } = useTranslation('privacyVerklaring');

  return (
    <Layout>
      <Seo title={t('seoTitle')} />
      <Cover>
        <PageSection center>
          <h1>{t('title')}</h1>
          <p>
            <i>{t('version')}</i>
          </p>
          <p>
            <Trans t={t} i18nKey="intro">
              <Link to="/" />
            </Trans>
          </p>

          <h4>{t('1.title')}</h4>
          <p>{t('1.p1')}</p>
          <ul className="list">
            <li>
              <Trans t={t} i18nKey="1.p2.1">
                <strong />
              </Trans>
            </li>
            <li>
              <Trans t={t} i18nKey="1.p2.2">
                <strong />
              </Trans>
            </li>
            <li>
              <Trans t={t} i18nKey="1.p2.3">
                <strong />
              </Trans>
            </li>
            <li>
              <Trans t={t} i18nKey="1.p2.4">
                <strong />
              </Trans>
            </li>
            <li>
              <Trans t={t} i18nKey="1.p2.5">
                <strong />
              </Trans>
            </li>
          </ul>

          <h4>{t('2.title')}</h4>
          <p>{t('2.p1')}</p>

          <h4 className="sub">{t('2.sub.1.title')}</h4>
          <p>{t('2.sub.1.p1')}</p>
          <p>{t('2.sub.1.p2')}</p>
          <ul className="list">
            <li>{t('2.sub.1.1')}</li>
            <li>{t('2.sub.1.2')}</li>
            <li>{t('2.sub.1.3')}</li>
          </ul>

          <h4 className="sub">{t('2.sub.2.title')}</h4>
          <p>{t('2.sub.2.p1')}</p>
          <p>{t('2.sub.2.p2')}</p>
          <ul className="list">
            <li>{t('2.sub.2.1')}</li>
            <li>{t('2.sub.2.2')}</li>
            <li>{t('2.sub.2.3')}</li>
            <li>{t('2.sub.2.4')}</li>
            <li>{t('2.sub.2.5')}</li>
            <li>{t('2.sub.2.6')}</li>
            <li>{t('2.sub.2.7')}</li>
          </ul>

          <h4 className="sub">{t('2.sub.3.title')}</h4>
          <p>{t('2.sub.3.p1')}</p>
          <p>{t('2.sub.3.p2')}</p>
          <ul className="list">
            <li>{t('2.sub.3.1')}</li>
            <li>{t('2.sub.3.2')}</li>
            <li>{t('2.sub.3.3')}</li>
            <li>{t('2.sub.3.4')}</li>
            <li>{t('2.sub.3.5')}</li>
          </ul>

          <h4 className="sub">{t('2.sub.4.title')}</h4>
          <p>{t('2.sub.4.p1')}</p>
          <p>{t('2.sub.4.p2')}</p>
          <ul className="list">
            <li>{t('2.sub.4.1')}</li>
            <li>{t('2.sub.4.2')}</li>
            <li>{t('2.sub.4.3')}</li>
            <li>{t('2.sub.4.4')}</li>
            <li>{t('2.sub.4.5')}</li>
          </ul>

          <h4 className="sub">{t('2.sub.5.title')}</h4>
          <p>{t('2.sub.5.p1')}</p>
          <p>{t('2.sub.5.p2')}</p>
          <ul className="list">
            <li>{t('2.sub.5.1')}</li>
            <li>{t('2.sub.5.2')}</li>
            <li>{t('2.sub.5.3')}</li>
          </ul>

          <h4 className="sub">{t('2.sub.6.title')}</h4>
          <p>{t('2.sub.6.p1')}</p>
          <p>{t('2.sub.6.p2')}</p>
          <ul className="list">
            <li>{t('2.sub.6.1')}</li>
            <li>{t('2.sub.6.2')}</li>
            <li>{t('2.sub.6.3')}</li>
            <li>{t('2.sub.6.4')}</li>
            <li>{t('2.sub.6.5')}</li>
            <li>{t('2.sub.6.6')}</li>
            <li>{t('2.sub.6.7')}</li>
          </ul>

          <h4>{t('3.title')}</h4>
          <p>{t('3.p1')}</p>

          <h4>{t('4.title')}</h4>
          <p>{t('4.p1')}</p>
          <ul className="list">
            <li>{t('4.list1.1')}</li>
            <li>{t('4.list1.2')}</li>
            <li>{t('4.list1.3')}</li>
            <li>{t('4.list1.4')}</li>
            <li>{t('4.list1.5')}</li>
          </ul>
          <p>{t('4.p2')}</p>
          <ul className="list">
            <li>{t('4.list2.1')}</li>
            <li>{t('4.list2.2')}</li>
            <li>{t('4.list2.3')}</li>
          </ul>
          <p>{t('4.p3')}</p>

          <h4>{t('5.title')}</h4>
          <p>{t('5.p1')}</p>

          <h4>{t('7.title')}</h4>
          <p>{t('7.p1')}</p>
          <p>{t('7.p2')}</p>

          <h4>{t('8.title')}</h4>
          <p>{t('8.p1')}</p>

          <h4>{t('9.title')}</h4>
          <p>{t('9.p1')}</p>
          <p>{t('9.p2')}</p>
          <ul className="list">
            <li>
              <Trans t={t} i18nKey="9.list.1">
                <strong />
              </Trans>
            </li>
            <li>
              <Trans t={t} i18nKey="9.list.2">
                <strong />
              </Trans>
            </li>
            <li>
              <Trans t={t} i18nKey="9.list.3">
                <strong />
              </Trans>
            </li>
            <li>
              <Trans t={t} i18nKey="9.list.4">
                <strong />
              </Trans>
            </li>
            <li>
              <Trans t={t} i18nKey="9.list.5">
                <strong />
              </Trans>
            </li>
            <li>
              <Trans t={t} i18nKey="9.list.6">
                <strong />
              </Trans>
            </li>
            <li>
              <Trans t={t} i18nKey="9.list.7">
                <strong />
              </Trans>
            </li>
          </ul>
          <p>{t('9.p3')}</p>
          <p>{t('9.p4')}</p>
          <p>
            <Trans t={t} i18nKey="9.p5">
              <a
                href="mailto:support@exonet.nl"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="support@exonet.nl"
              />
            </Trans>
          </p>

          <h4>{t('10.title')}</h4>
          <p>{t('10.p1')}</p>

          <h4>{t('11.title')}</h4>
          <p>
            <Trans t={t} i18nKey="11.p1">
              <a
                href="https://autoriteitpersoonsgegevens.nl/nl/zelf-doen/gebruik-uw-privacyrechten/klacht-melden-bij-de-ap"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Autoriteit Persoonsgegevens"
              />
            </Trans>
          </p>

          <p>
            <Trans t={t} i18nKey="closingNote">
              <Link to="/contact" />
            </Trans>
          </p>
        </PageSection>
      </Cover>
    </Layout>
  );
};

export default withTranslations(Page);
